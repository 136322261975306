
import { typesTags } from "../../tags"; // Ensure you import typesTags
import { transformResponse } from "../helpers";
import { injectApiEndpoints } from "../../api";

const url = "/administrative-type";
const appendUrl = (segment) => `${url}/${segment}`;

const adminTypeApis = injectApiEndpoints({
    endpoints: (builder) => ({
        createAdminType: builder.mutation({
            query: (body) => ({
                url: `${url}/add`, // Use "add" for create
                method: "post",
                body,
            }),
            invalidatesTags: [typesTags],
        }),

        fetchAdminTypesBySubcategory: builder.query({
            query: (subcategoryId) => ({
                url: `${url}/subcategory/${subcategoryId}`, // Fetch types by subcategory
                method: "get",
            }),
            transformResponse,
            providesTags: [typesTags],
        }),

        updateAdminType: builder.mutation({
            query: ({ id, ...body }) => ({
                url: `${url}/update/${id}`, // Use "update" for update
                method: "put",
                body,
            }),
            invalidatesTags: [typesTags],
        }),

        deleteAdminType: builder.mutation({
            query: (id) => ({
                url: `${url}/delete/${id}`, // Use "delete" for delete
                method: "delete",
            }),
            invalidatesTags: [typesTags],
        }),
        fetchAdminTypeById: builder.query({
            query: (id) => ({
                url: `${url}/by-id/${id}`, 
                method: "get",
            }),
            transformResponse,
            providesTags: [typesTags],
        }),
    }),
});

export const {
    useCreateAdminTypeMutation,
    useFetchAdminTypesBySubcategoryQuery,
    useUpdateAdminTypeMutation,
    useDeleteAdminTypeMutation,
    useFetchAdminTypeByIdQuery
} = adminTypeApis;
