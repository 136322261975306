import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useCreatePromotionSectionMutation, useUpdatePromotionSectionMutation } from 'redux/queries/cms/homeCms';
import { successToast, errorToast } from 'utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import PromotionCard from 'components/cms/promotions-card';

const PromotionalSection = ({ setFieldValue, values, pageId, promotionData }) => {
  const [createPromotionSection] = useCreatePromotionSectionMutation();
  const [updatePromotionSection] = useUpdatePromotionSectionMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const promotionNames = ['Promotion 1', 'Promotion 2', 'Promotion 3', 'Promotion 4'];

  useEffect(() => {
    if (promotionData?.sectionDetails?.promotions) {
      const prefilledPromotions = promotionNames.map((promotionName, index) => {
        const promotion = promotionData.sectionDetails.promotions[index];
        return {
          title: promotion?.title || promotionName,
          description: promotion?.description || '',
          image: promotion?.image || null,
        };
      });
      setFieldValue('promotions', prefilledPromotions);
    }

    if (promotionData?.sectionDetails?.primaryHeading) {
      setFieldValue('primaryHeading', promotionData.sectionDetails.primaryHeading);
    }
  }, [promotionData, setFieldValue]);

  if (!values.promotions) {
    setFieldValue('promotions', Array(promotionNames.length).fill({ title: '', description: '', image: null }));
  }

  const handleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('pageId', pageId);
    formData.append('sectionName', 'promotions');
    formData.append('pageName', 'home');
    formData.append('primaryHeading', values.primaryHeading);

    values.promotions.forEach((promotion, index) => {
      const promotionName = promotionNames[index].toLowerCase().replace(/ /g, '');
      formData.append(`${promotionName}Title`, promotion.title);
      formData.append(`${promotionName}Description`, promotion.description);
      if (promotion.image) {
        formData.append(`${promotionName}Image`, promotion.image);
      }
    });

    try {
      if (promotionData?._id) {
        await updatePromotionSection({ body: formData, sectionId: promotionData._id }).unwrap();
        successToast('Promotion Section updated successfully!');
      } else {
        await createPromotionSection(formData).unwrap();
        successToast('Promotion Section created successfully!');
      }
    } catch (error) {
      errorToast('Error handling Promotion Section!');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageChange = (e, index) => {
    const updatedPromotions = [...values.promotions];
    updatedPromotions[index] = { ...updatedPromotions[index], image: e.target.files[0] };
    setFieldValue('promotions', updatedPromotions);
  };
  return (
    <div className="section mb-2 p-2 rounded-lg shadow-md">
      <div className="mb-6">
        <label className="block text-md font-semibold  mb-2">Primary Heading</label>
        <input
          type="text"
          className="form-control w-full px-4 py-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
          placeholder="Enter primary heading"
          value={values.primaryHeading || ''}
          onChange={(e) => setFieldValue('primaryHeading', e.target.value)}
        />
      </div>


      <div className="swiper-container bg-gray-100 rounded-lg p-3 relative">
        <Swiper
          modules={[Navigation]}
          spaceBetween={30}
          slidesPerView={3}
          breakpoints={{
            1024: { slidesPerView: 3 },
            768: { slidesPerView: 2 },
            480: { slidesPerView: 1 },
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            enabled: true,
          }}
          style={{
            '--swiper-navigation-size': '20px',
            '--swiper-navigation-sides-offset': '-15px',
          }}
        >
          {values.promotions.map((promotion, index) => (
            <SwiperSlide key={index}>
              <PromotionCard
                promotion={promotion}
                index={index}
                handleImageChange={handleImageChange}
                setFieldValue={setFieldValue}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="flex justify-end mt-6">
        <Button
          onClick={handleSubmit}
          className="w-1/2 sm:w-1/3 md:w-1/4 btn-primary text-white font-bold rounded-lg transition-all duration-200"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : promotionData?._id ? 'Update Promotion Section' : 'Submit Promotion Section'}
        </Button>
      </div>
    </div>
  );
};

export default PromotionalSection;
