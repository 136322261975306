import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCategoriesOptionsByDomainQuery, useGetEducationCourseByIdQuery, useUpdateEducationCourseMutation } from '../../../redux/queries';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import TextField from 'components/fields/TextField';
import DropZone from 'components/dropzone';
import { Button, Label, Row, Col } from 'reactstrap';
import { successToast } from 'utils';
import { ErrorMessage } from "components/common/error-msg";
import EditCourseForm from 'components/DynamicForm/EditCourseForm';

const EditEducationCourse = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data: response, isLoading } = useGetEducationCourseByIdQuery(id);
    const [updateEducationCourse] = useUpdateEducationCourseMutation();
    const [currentStep, setCurrentStep] = useState(1);
    const [dynamicFormData, setDynamicFormData] = useState(null);
    const course = response;
    const { data: categories, isLoading: categoriesLoading } =
        useGetCategoriesOptionsByDomainQuery("education", { skip: !"education" });

    const validationSchemaStep1 = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        category: Yup.string().required('Category is required'),
        description: Yup.string(),
        image: Yup.mixed().required('Image is required'),
    });

    const validationSchemaStep2 = Yup.object().shape({
        form: Yup.object().shape({
            groups: Yup.array().of(
                Yup.object().shape({
                    groupName: Yup.string().required('Group name is required'),
                    fields: Yup.array().of(
                        Yup.object().shape({
                            label: Yup.string().required('Field label is required'),
                            fieldType: Yup.string().required('Field type is required'),
                        })
                    ),
                })
            ).required('At least one group is required')
        })
    });

    const handleDynamicFormSubmit = (formData) => {
        setDynamicFormData(formData);
        setCurrentStep(2);
    };

    const handleSubmit = async (values) => {
        const formData = new FormData();

        formData.append("name", values.name);
        formData.append("category", values.category);
        if (values.image && values.image.length > 0) {
            formData.append("image", values.image[0]);
        }
        formData.append("description", values.description);
        formData.append("form", JSON.stringify(values?.form));
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }

        console.log("BeforeBeforeBeforeBefore updating course:", formData);

        try {
            const res = await updateEducationCourse({ id, body: formData }).unwrap();
            successToast(res?.message || 'Course updated successfully!');
            navigate('/admin/all-education-courses');
        } catch (error) {
            console.error('Error updating education course:', error);
            const errorMessage = error?.data?.message || 'An error occurred while updating the course.';
            successToast(errorMessage);
        }
    };

    if (isLoading) return <p>Loading...</p>;

    return (
        <div className="p-5 bg-white">
            <h2 className="text-lg font-bold">Edit Education Course</h2>

            <Formik
                initialValues={{
                    name: course?.name || '',
                    category: course?.category || '',
                    image: course?.image ? [course.image] : [],
                    description: course?.description || '',
                    form: course?.dynamicForm || {
                        groups: [
                            {
                                groupName: '',
                                fields: [
                                    {
                                        label: '',
                                        fieldType: 'text',
                                        options: []
                                    }
                                ]
                            }
                        ]
                    }
                }}
                validationSchema={currentStep === 1 ? validationSchemaStep1 : validationSchemaStep2}
                onSubmit={currentStep === 2 ? handleSubmit : null}
            >
                {({ values, errors, setFieldValue }) => (
                    <Form>
                        {currentStep === 1 && (
                            <div>
                                <Row>
                                    <Col md={6}>
                                        <div className="mb-4">
                                            <Field
                                                label="Course Name"
                                                placeholder="Enter course name"
                                                name="name"
                                                component={InputField}
                                            />
                                            <ErrorMessage error={errors.name} />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-4">
                                            <Label for="category">Category</Label>
                                            <Field
                                                name="category"
                                                component={SelectField}
                                                placeholder="Select category"
                                                selectorOptions={categories}
                                                selectorLabelKey="label"
                                                selectorValueKey="value"
                                            />
                                            <ErrorMessage error={errors.category} />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="mb-4">
                                            <Field
                                                label="Description"
                                                placeholder="Enter course description"
                                                name="description"
                                                component={TextField}
                                            />
                                            <ErrorMessage error={errors.description} />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="mb-4">

                                            <DropZone
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                errors={errors.image}
                                                multiple={false}
                                                name="image"
                                                accept={{ "image/*": [".png", ".jpg", ".jpeg", ".svg", ".webp"] }}
                                                label="Upload Image"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="d-flex justify-content-end">
                                    <Button type="button" onClick={() => setCurrentStep(2)}>
                                        Next
                                    </Button>
                                </div>
                            </div>
                        )}

                        {currentStep === 2 && (
                            <div>
                                <EditCourseForm
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    dynamicForm={values.form}
                                    onSubmit={handleDynamicFormSubmit}
                                />
                                <div className="d-flex justify-content-between mt-4">
                                    <Button type="button" onClick={() => setCurrentStep(1)}>
                                        Previous
                                    </Button>
                                    <Button type="submit">Update Course</Button>
                                </div>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EditEducationCourse;
