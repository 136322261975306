import React from "react";
import Card from "components/card";

import AddCategoryForm from "components/Category/add-category-form";

const AddCultureCategory = () => {
  return (
    <Card extra="flex w-full h-full mt-4 flex-col">
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Add New Culture Category
        </h4>
      </div>
      <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-1 2xl:grid-cols-1">
        <AddCategoryForm domain="culture" />
      </div>
    </Card>
  );
};

export default AddCultureCategory;
