import React from "react";
import DomainCategories from "components/Category/DomainCategories";

const ArchitectureCategories = () => {
  return (
    <>
      <DomainCategories domain="architecture" />
    </>
  );
};

export default ArchitectureCategories;
