import React, { useState } from "react";
import InputField from "components/fields/InputField";
import { Button, Modal, FormGroup, Label, Input } from "reactstrap";
import { toast } from "react-toastify"; 
import { useSendResetLinkMutation, useUpdatePasswordByAdminMutation } from "../../redux/queries";
import { AiOutlineClose } from 'react-icons/ai'; // Import close icon from react-icons

const ResetPasswordModal = ({ isOpen, onClose, email }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSendLink, setIsSendLink] = useState(true); 

  const [sendResetLink] = useSendResetLinkMutation();
  const [updatePassword] = useUpdatePasswordByAdminMutation();

  const handleSendResetLink = async () => {
    try {
      await sendResetLink({ email }).unwrap();
      toast.success('Reset link sent successfully!');
    } catch (error) {
      console.error('Failed to send reset link:', error);
      toast.error('Failed to send reset link.');
    }
  };

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      console.error('Passwords do not match');
      toast.error('Passwords do not match');
      return;
    }

    try {
      const response = await updatePassword({ email, newPassword }).unwrap();
      if (response.message) {
        console.log(response.message);
        onClose();
        toast.success('Password updated successfully!');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      toast.error('Error updating password.');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      className="max-w-2xl h-auto p-6 mx-auto"
      centered
    >
      <div className="bg-white rounded-lg shadow-lg p-4 relative">
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          aria-label="Close"
        >
          <AiOutlineClose size={24} />
        </button>
        
        <h2 className="text-lg font-bold mb-4">Reset Password</h2>
        
        <FormGroup>
          <Label>Choose an option:</Label>
          <div className="d-flex">
            <div className="me-3">
              <Input
                type="radio"
                id="sendLink"
                checked={isSendLink}
                onChange={() => setIsSendLink(true)}
              />
              <Label for="sendLink" className="ms-2">Send Reset Link</Label>
            </div>
            <div>
              <Input
                type="radio"
                id="resetPassword"
                checked={!isSendLink}
                onChange={() => setIsSendLink(false)}
              />
              <Label for="resetPassword" className="ms-2">Update Password Directly</Label>
            </div>
          </div>
        </FormGroup>

        <InputField
          label="Email"
          value={email}
          readOnly
          placeholder="Enter your email"
          className="mb-4"
        />

        {isSendLink ? (
          <Button
            color="primary"
            onClick={handleSendResetLink}
            className="w-full mt-2"
          >
            Send Reset Link
          </Button>
        ) : (
          <>
            <InputField
              label="New Password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
              className="mb-4"
            />
            <InputField
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm new password"
              className="mb-4"
            />
            <Button
              color="primary"
              onClick={handleUpdatePassword}
              className="w-full mt-2"
            >
              Update Password
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ResetPasswordModal;
