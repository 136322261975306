import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const SubmittedFormModal = ({ isOpen, onClose, formData }) => {
    return (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            className="max-w-4xl" // Increased max width
        >
            <ModalHeader
                toggle={onClose}
                className="bg-gray-100 border-b border-gray-300"
            >
                <h5 className="font-bold text-xl">Form Data</h5>
            </ModalHeader>
            <ModalBody className="max-h-[600px] overflow-y-auto p-5"> {/* Increased max height */}
                {formData.groups.map((group, index) => (
                    <div key={index} className="mb-6 border border-gray-300 rounded-lg shadow-sm p-4">
                        <h5 className="font-extrabold mb-4">{group.groupName}</h5>
                        <div className="grid grid-cols-2 gap-4">
                            {group.fields.map((field, idx) => (
                                <div key={idx} className="mb-3">
                                    <label className="font-medium">{field.label}:</label>
                                    <input
                                        type="text"
                                        className="form-control w-full bg-gray-200 cursor-not-allowed border border-gray-400 rounded p-2"
                                        value={field.inputData || 'N/A'}
                                        readOnly
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-secondary" onClick={onClose}>
                    Close
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default SubmittedFormModal;
