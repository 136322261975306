import React, { useState } from 'react';
import { FaEye } from 'react-icons/fa';
import DataTable from 'components/dataTable/reactDataTable';
import { useGetAllContactsQuery } from '../../../redux/queries';
import ContactMessageModal from 'components/ContactModal/ContactMessageModal';
import CircularPagination from 'components/pagination/CircularPagination';

const AllContacts = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 10;

    const { data: responseData, isLoading, error } = useGetAllContactsQuery({ page: currentPage, limit });

    const contacts = responseData?.contacts || [];
    const totalPages = responseData?.totalPages || 0;

    const [selectedContact, setSelectedContact] = useState(null);

    const viewContactDetails = (contact) => {
        setSelectedContact(contact);
    };

    const columns = [
        {
            name: 'First Name',
            selector: row => row.firstName,
            cell: row => row.firstName || 'N/A'
        },
        {
            name: 'Last Name',
            selector: row => row.lastName,
            cell: row => row.lastName || 'N/A'
        },
        {
            name: 'Email',
            selector: row => row.email,
            cell: row => row.email || 'N/A'
        },
        {
            name: 'Contact',
            selector: row => row.contact,
            cell: row => row.contact || 'N/A'
        },
        {
            name: 'Created At',
            selector: row => new Date(row.createdAt).toLocaleDateString(),
            cell: row => new Date(row.createdAt).toLocaleDateString() || 'N/A'
        },
        {
            name: 'Actions',
            cell: row => (
                <div className="flex space-x-2">
                    <button
                        className="text-blue-500 hover:text-blue-700"
                        onClick={() => viewContactDetails(row)}
                    >
                        <FaEye />
                    </button>
                </div>
            )
        }
    ];

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="p-5">
            <h4 className="text-lg font-bold">All Contacts</h4>
            {contacts.length > 0 ? (
                <>
                    <DataTable
                        columns={columns}
                        data={contacts}
                        responsive
                    />
                    <CircularPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </>
            ) : (
                <p>No contacts available.</p>
            )}
            {selectedContact && (
                <ContactMessageModal
                    isOpen={!!selectedContact}
                    onClose={() => setSelectedContact(null)}
                    contactData={selectedContact}
                />
            )}
        </div>
    );
};


export default AllContacts;
