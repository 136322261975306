import React from 'react';

const PromotionCard = ({ promotion, index, handleImageChange, setFieldValue }) => {
  const renderImagePreview = () => {
    if (promotion.image instanceof File) {
      return (
        <img
          src={URL.createObjectURL(promotion.image)}
          alt={`Promotion ${index + 1}`}
          className="w-full h-48 object-cover rounded-md"
        />
      );
    }

    if (typeof promotion.image === 'string') {
      return (
        <img
          src={promotion.image}
          alt={`Promotion ${index + 1}`}
          className="w-full h-48 object-cover rounded-md"
        />
      );
    }

    return (
      <div className="h-48 bg-gray-200 rounded-md flex items-center justify-center text-gray-400">
        No Image Preview
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg  p-4 flex flex-col items-center justify-between h-full">
      <div className="mb-4 w-full">
        <input
          type="file"
          onChange={(e) => handleImageChange(e, index)}
          accept="image/*"
          className="hidden"
          id={`promotion-image-${index}`}
        />
        <label htmlFor={`promotion-image-${index}`} className="cursor-pointer block">
          {renderImagePreview()}
        </label>
      </div>

      <div className="w-full mb-4">
        <label htmlFor={`promotion-title-${index}`} className="font-medium text-lg text-gray-700">
          Title
        </label>
        <input
          type="text"
          id={`promotion-title-${index}`}
          value={promotion.title || ''}
          onChange={(e) => {
            const updatedPromotion = { ...promotion, title: e.target.value };
            setFieldValue(`promotions[${index}]`, updatedPromotion);
          }}
          placeholder={`Enter title for Promotion ${index + 1}`}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>

      <div className="w-full mb-4">
        <label htmlFor={`promotion-description-${index}`} className="font-medium text-lg text-gray-700">
          Description
        </label>
        <textarea
          id={`promotion-description-${index}`}
          value={promotion.description || ''}
          onChange={(e) => {
            const updatedPromotion = { ...promotion, description: e.target.value };
            setFieldValue(`promotions[${index}]`, updatedPromotion);
          }}
          placeholder={`Enter description for Promotion ${index + 1}`}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>
    </div>
  );
};

export default PromotionCard;
