import React, { useState } from 'react';
import * as Yup from "yup";
import { Formik, Field, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import SelectField from 'components/fields/SelectField';
import { Button, Label, Col, Row } from 'reactstrap';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import DropZone from 'components/dropzone';
import { useCreateEducationCourseMutation, useGetCategoriesOptionsByDomainQuery, } from '../../../redux/queries';
import DynamicFormModel from 'components/DynamicForm';
import { ErrorMessage } from "components/common/error-msg";
import { successToast } from 'utils';

const AddEducationCourse = () => {
  const navigate = useNavigate();
  const [createEducationCourse] = useCreateEducationCourseMutation();
  const [currentStep, setCurrentStep] = useState(1);
  const [dynamicFormData, setDynamicFormData] = useState(null);
  const { data: categories, isLoading: categoriesLoading } =
    useGetCategoriesOptionsByDomainQuery("education", {
      skip: !"education",
    });
  const handleDynamicFormSubmit = (formData) => {
    setDynamicFormData(formData);
    setCurrentStep(3);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const { name, category, image, description, form } = values;
    const formData = new FormData
    formData.append("name", name)
    formData.append("category", category)
    formData.append("image", image[0])
    formData.append("description", description)
    formData.append("form", JSON.stringify(form))
    try {
      const res = await createEducationCourse(formData).unwrap();
      successToast(res.message);
      resetForm();
      navigate(-1);
    } catch (error) {
      console.error('Error creating education course:', error);
    }
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    category: Yup.string().required('Category is required'),
    form: Yup.object().shape({
      groups: Yup.array().of(
        Yup.object().shape({
          groupName: Yup.string()
            .required('Group name is required'),
          fields: Yup.array().of(
            Yup.object().shape({
              label: Yup.string()
                .required('Field label is required'),
              fieldType: Yup.string()
                .required('Field type is required'),
            })
          ),
        })
      ),
    }),
  });

  return (
    <div className="p-5 bg-white">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Add Education Course</h2>
      </div>

      <Formik
        initialValues={{
          name: '',
          category: '',
          image: null,
          description: '',
          form: {
            groups: [
              {
                groupName: '',
                fields: [
                  {
                    label: '',
                    fieldType: 'text',
                    options: []
                  }
                ]
              }
            ]
          }
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            {currentStep === 1 && (
              <div>
                <Row>
                  <Col md={6}>
                    <div className="mb-4">
                      <Field
                        label="Course Name"
                        placeholder="Enter course name"
                        type="text"
                        name="name"
                        component={InputField}
                      />
                      <ErrorMessage error={errors.name} />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-4">
                      <Label for="category">Category</Label>
                      <Field
                        name="category"
                        label="Category"
                        component={SelectField}
                        placeholder="Select category"
                        selectorOptions={categories} // Map categories
                        isLoading={categoriesLoading}
                        selectorLabelKey="label"
                        selectorValueKey="value"
                      />

                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-4">
                      <Field
                        label="Description"
                        placeholder="Enter course description"
                        name="description"
                        component={TextField}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-4">
                      <DropZone
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors?.image}
                        multiple={false}
                        name="image"
                        accept={{ "image/*": [".png", ".jpg", ".jpeg", ".svg", ".webp"] }}
                        label="Upload Images"
                      />
                    </div>
                  </Col>

                </Row>
                <div className="d-flex justify-content-end">
                  <Button type="button" onClick={() => setCurrentStep(2)}>
                    Next
                  </Button>
                </div>
              </div>
            )}

            {currentStep === 2 && (
              <div>
                <DynamicFormModel
                  onSubmit={handleDynamicFormSubmit}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <div className="d-flex justify-content-between mt-4">
                  <Button type="button" onClick={() => setCurrentStep(1)}>
                    Previous
                  </Button>
                  <Button type="submit">
                    Add Course
                  </Button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddEducationCourse;
