import { injectApiEndpoints } from "redux/api";
import { transformResponse } from "../helpers";


export const CMSPagesApi = injectApiEndpoints({
  endpoints: (build) => ({
    createPage: build.mutation({
      query: (body) => ({
        url: "/public/pages/create", 
        method: "post",
        body,
      }),
    }),

    getPage: build.query({
      query: (pageName) => ({
        url: `/public/pages/${pageName}`, 
        method: "get",
      }),
      transformResponse,
    }),
  }),
});

export const {
  useCreatePageMutation,
  useGetPageQuery,
} = CMSPagesApi;
