import React from 'react';
import { Field, FieldArray } from 'formik';
import { Button, Label, FormGroup, Input } from 'reactstrap';
import { FaPlus, FaTrash } from 'react-icons/fa'; // Added more icons
import { IoCloseCircleOutline } from 'react-icons/io5';
import { ErrorMessage } from "components/common/error-msg";
const DynamicFormModel = ({ values, errors, setFieldValue }) => {

  return (
    <FieldArray name="form.groups">
      {({ remove: removeGroup, push: pushGroup }) => (
        <>
          {values?.form?.groups?.length > 0 && values?.form?.groups?.map((group, groupIndex) => (
            <div key={groupIndex} className="relative mb-8 p-6 border border-gray-300 rounded-lg bg-white shadow-lg">
              <div className="absolute top-2 right-2">
                <Button
                  type="button"
                  onClick={() => {
                    if (values?.form?.groups?.length > 1) {
                      removeGroup(groupIndex);
                    }
                  }}
                  color="danger"
                  className="flex items-center justify-center p-2 text-red-700 bg-red-100 rounded-full hover:bg-red-300"
                >
                  <IoCloseCircleOutline size={22} />
                </Button>
              </div>

              {/* Group Heading */}
              <h4 className="text-xl font-semibold text-center mb-6">
                Group {groupIndex + 1}
              </h4>

              <div className="mb-6">
                <Field
                  name={`form.groups[${groupIndex}].groupName`}
                  type="text"
                  as={Input}
                  placeholder="Enter group name"
                  className="w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"
                />
                <ErrorMessage error={errors?.form?.groups[groupIndex]?.groupName} />
              </div>
              <FieldArray name={`form.groups[${groupIndex}].fields`}>
                {({ remove: removeField, push: pushField }) => (
                  <>
                    {group?.fields?.length > 0 && group?.fields?.map((field, fieldIndex) => (
                      <div key={fieldIndex} className="mb-6 p-6 border border-gray-300 rounded-md bg-white shadow-md">
                        <div className="flex items-center justify-between mb-4">
                          <div className="flex-1 mr-4">
                            {/* Field Label */}
                            <FormGroup>
                              <Label htmlFor={`form.groups.${groupIndex}.fields.${fieldIndex}.label`} className="text-sm font-medium text-gray-700">Field Label</Label>
                              <Field
                                name={`form.groups.${groupIndex}.fields.${fieldIndex}.label`}
                                type="text"
                                as={Input}
                                id={`form.groups.${groupIndex}.fields.${fieldIndex}.label`}
                                placeholder="Enter field label"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"
                              />
                              <ErrorMessage error={errors?.form?.groups[groupIndex]?.fields[fieldIndex]?.label} />
                            </FormGroup>
                          </div>
                          <div className="flex-1 mr-4">
                            {/* Field Type */}
                            <FormGroup>
                              <Label htmlFor={`form.groups.${groupIndex}.fields.${fieldIndex}.fieldType`} className="text-sm font-medium text-gray-700">Field Type</Label>
                              <Field
                                name={`form.groups.${groupIndex}.fields.${fieldIndex}.fieldType`}
                                as="select"
                                id={`form.groups.${groupIndex}.fields.${fieldIndex}.fieldType`}
                                onChange={e => {
                                  const value = e.target.value;
                                  setFieldValue(`form.groups.${groupIndex}.fields.${fieldIndex}.fieldType`, value);
                                  if (['radio', 'checkbox', 'select'].includes(value)) {
                                    // Ensure there is at least one option and set the first option as default
                                    setFieldValue(`form.groups.${groupIndex}.fields.${fieldIndex}.options`, (values?.form?.groups[groupIndex]?.fields[fieldIndex]?.options?.length > 0 ? values.form.groups[groupIndex].fields[fieldIndex].options : ['']));
                                  } else {
                                    setFieldValue(`form.groups.${groupIndex}.fields.${fieldIndex}.options`, []);
                                  }
                                }}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-white focus:outline-none focus:ring-2 focus:ring-red-500"
                              >
                                <option value="text">Text</option>
                                <option value="textarea">Textarea</option>
                                <option value="select">Select</option>
                                <option value="radio">Radio</option>
                                <option value="checkbox">Checkbox</option>
                              </Field>
                              <ErrorMessage error={errors?.form?.groups[groupIndex]?.fields[fieldIndex]?.fieldType} />
                            </FormGroup>
                          </div>
                          <Button
                            type="button"
                            onClick={() => {
                              if (group?.fields?.length > 1) {
                                removeField(fieldIndex);
                              }
                            }}
                            color="danger"
                            className="flex items-center justify-center p-2 text-red-700 bg-red-100 rounded-full hover:bg-red-300"
                          >
                            <FaTrash size={18} />
                          </Button>
                        </div>

                        {/* Field Options */}
                        {['radio', 'checkbox', 'select'].includes(field.fieldType) && (
                          <FormGroup className="mb-4">
                            <Label className="text-sm font-medium text-gray-700">Options</Label>
                            <FieldArray name={`form.groups[${groupIndex}].fields[${fieldIndex}].options`}>
                              {({ push: pushOption, remove: removeOption }) => (
                                <>
                                  {field?.options?.length > 0 && field.options.map((option, optionIndex) => (
                                    <div key={optionIndex} className="flex items-center mb-2 space-x-3">
                                      <Field
                                        name={`form.groups[${groupIndex}].fields[${fieldIndex}].options[${optionIndex}]`}
                                        type="text"
                                        as={Input}
                                        placeholder={`Option ${optionIndex + 1}`}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500"
                                      />
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          if (field?.options?.length > 1) {
                                            removeOption(optionIndex);
                                          }
                                        }}
                                        color="danger"
                                        className="ml-2 flex items-center justify-center p-1 text-red-700 bg-red-100 rounded-full hover:bg-red-200"
                                      >
                                        <FaTrash size={14} />
                                      </Button>
                                    </div>
                                  ))}
                                  <Button
                                    type="button"
                                    onClick={() => pushOption('')}
                                    color="primary"
                                    className="mt-2 flex items-center justify-center px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
                                  >
                                    <FaPlus size={14} />
                                    <span className="ml-2">Add Option</span>
                                  </Button>
                                </>
                              )}
                            </FieldArray>
                          </FormGroup>
                        )}
                      </div>
                    ))}
                    <div className="flex justify-end">
                      <Button
                        type="button"
                        onClick={() => pushField({ label: '', fieldType: 'text', options: [] })}
                        color="primary"
                        className="flex items-center justify-center px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
                        style={{ width: '200px' }}
                      >
                        <FaPlus size={14} />
                        <span className="ml-2">Add Field</span>
                      </Button>
                    </div>
                  </>
                )}
              </FieldArray>
            </div>
          ))}
          <div className="flex justify-center">
            <Button
              type="button"
              onClick={() => pushGroup({ groupName: '', fields: [{ label: '', fieldType: 'text', options: [] }] })}
              color="primary"
              className="flex items-center justify-center px-4 py-2 text-white bg-red-200 rounded-md hover:bg-red-400"
              style={{ width: '200px' }}
            >
              <FaPlus size={14} />
              <span className="ml-2">Add Group</span>
            </Button>
          </div>
        </>
      )}
    </FieldArray>
  );
};

export default DynamicFormModel;
