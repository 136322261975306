import React from "react";
import { Field, Form, Formik } from "formik";
import { Col, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import { successToast, errorToast } from "utils";
import TextField from "components/fields/TextField";
import { ErrorMessage } from "components/common/error-msg";
import { useNavigate } from "react-router-dom";
import DropZone from "components/dropzone";
import { convertToFormData } from "utils";
import { useCreateAdminCategoryMutation, useFetchParentCategoriesQuery } from "../../../../redux/queries";
import Select from "react-select";

const AddAdminCategoryForm = ({ id }) => {
  const [createAdminCategory] = useCreateAdminCategoryMutation();
  const navigate = useNavigate();
  const { data: categories = [], isLoading } = useFetchParentCategoriesQuery();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = convertToFormData(values);
      const response = await createAdminCategory(formData).unwrap();
      successToast(response.message);
      resetForm();
      navigate('/admin/all-administrative-categories');
    } catch (error) {
      errorToast(error.message);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    nameFr: Yup.string().required("French Name is required"),
    logo: Yup.mixed().required("Logo is required"),
    description: Yup.string().required("Description is required"),
    descriptionFr: Yup.string().required("French Description is required"),
    parent: Yup.string().nullable(),
  });

  return (
    <div className="p-4 bg-white rounded shadow-md">
      <h2 className="text-xl font-bold mb-4">
        {id ? "Add New Subcategory" : "Add New Administrative Category"}
      </h2>
      <Formik
        initialValues={{
          name: "",
          nameFr: "",
          logo: null,
          description: "",
          descriptionFr: "",
          parent: id || null,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Row>
              <Col md={6} className="mb-4">
                <FormGroup>
                  <Field
                    label="Name"
                    placeholder="Enter your Name"
                    name="name"
                    component={InputField}
                  />
                  <ErrorMessage error={errors.name} />
                </FormGroup>
              </Col>

              <Col md={6} className="mb-4">
                <FormGroup>
                  <Field
                    label="Name (French)"
                    placeholder="Enter your Name in French"
                    name="nameFr"
                    component={InputField}
                  />
                  <ErrorMessage error={errors.nameFr} />
                </FormGroup>
              </Col>

              <Col md={6} className="mb-4">
                <FormGroup>
                  <Field
                    label="Description"
                    placeholder="Enter Description"
                    name="description"
                    component={TextField}
                  />
                  <ErrorMessage error={errors.description} />
                </FormGroup>
              </Col>

              <Col md={6} className="mb-4">
                <FormGroup>
                  <Field
                    label="Description (French)"
                    placeholder="Enter Description in French"
                    name="descriptionFr"
                    component={TextField}
                  />
                  <ErrorMessage error={errors.descriptionFr} />
                </FormGroup>
              </Col>

              <Col md={12} className="mb-4">
                <FormGroup>
                  <DropZone
                    setFieldValue={setFieldValue}
                    name="logo"
                    label="Add a Logo"
                    accept={{
                      "image/*": [".png", ".jpg", ".jpeg", ".svg", ".webp"],
                    }}
                    values={values}
                  />
                  <ErrorMessage error={errors.logo} />
                </FormGroup>
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="linear rounded bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700"
              >
                Add Category
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddAdminCategoryForm;
