import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { Edit } from "react-feather";
import {
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { successToast, errorToast } from "utils";
import InputField from "components/fields/InputField";
import { yup } from "utils/constants";
import DropZone from "components/dropzone";
import TextField from "components/fields/TextField";
import { useModal } from "utils/useModal";
import { ErrorMessage } from "components/common/error-msg";
import { convertToFormData } from "utils";
import { useUpdateAdminCategoryMutation } from "../../../../redux/queries";

const validationSchema = yup.object().shape({
  name: yup.string().label("Name").required(),
  nameFr: yup.string().label("Name (French)").required(),
  logo: yup.mixed().required("Logo is required"),
  description: yup.string().label("Description").required(),
  descriptionFr: yup.string().label("Description (French)").required(),
});

const EditAdministrativeCategory = ({ user }) => {
  console.log('useruser', user)
  const [selectedCategory, setSelectedCategory] = useState({
    name: user.name || "",
    nameFr: user.nameFr || "",
    logo: user.logo ? [user.logo] : [],
    description: user.description || "",
    descriptionFr: user.descriptionFr || "",
  });
  const modal = useModal();
  const [mutate] = useUpdateAdminCategoryMutation();

  const handleOpenModal = () => {
    setSelectedCategory({
      name: user.name || "",
      nameFr: user.nameFr || "",
      logo: user.logo ? [user.logo] : [],
      description: user.description || "",
      descriptionFr: user.descriptionFr || "",
    });
    modal.open();
  };

  const handleSubmit = async (values) => {
    const id = user?._id;
    try {
      const body = convertToFormData(values);
      const res = await mutate({ id, body }).unwrap();
      successToast(res.message);
      modal.close();
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <>
      <span
        className="flex items-center gap-3"
        role="button"
        onClick={handleOpenModal}
      >
        <Edit className="me-2" size={15} />
      </span>
      <Modal isOpen={modal.show} toggle={modal.toggle}>
        <ModalHeader toggle={modal.toggle}>Edit Category</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={selectedCategory}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              setFieldValue,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <Form>
                <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
                  <div className="px-3 py-3">
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <InputField
                            label="Name"
                            placeholder="Enter Name"
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage error={errors.name} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <InputField
                            label="Name (French)"
                            placeholder="Enter Name (French)"
                            type="text"
                            name="nameFr"
                            value={values.nameFr}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage error={errors.nameFr} />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Field
                            label="Description"
                            placeholder="Enter Description"
                            type="text"
                            name="description"
                            rows={5}
                            component={TextField}
                          />
                          <ErrorMessage error={errors.description} />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Field
                            label="Description (French)"
                            placeholder="Enter Description (French)"
                            type="text"
                            name="descriptionFr"
                            rows={5}
                            component={TextField}
                          />
                          <ErrorMessage error={errors.descriptionFr} />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <DropZone
                            setFieldValue={setFieldValue}
                            errors={errors.logo}
                            values={values}
                            label="Add a Logo"
                            name="logo"
                            multiple={false}
                            accept={{
                              "image/*": [".png", ".jpg", ".jpeg", ".svg", ".webp"],
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditAdministrativeCategory;
