import React from "react";
import { useParams } from "react-router-dom"; // Import useParams
import Card from "components/card";
import AddAdminCategoryForm from "./AddAdminCategoryForm";

const AddAdministrativeCategory = () => {
  const { parentId } = useParams(); 
  return (
    <Card extra="flex w-full h-full mt-4 flex-col">
      <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-1 2xl:grid-cols-1">
        <AddAdminCategoryForm id={parentId} /> 
      </div>
    </Card>
  );
};

export default AddAdministrativeCategory;
