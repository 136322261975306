import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Link } from "react-router-dom";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { useDeleteAdminCategoryMutation, useFetchParentCategoriesQuery } from "../../../../redux/queries";
import SelectField from "components/fields/SelectField";

import { Button } from "components/ui-elements/Button";
import DeleteModal from "components/AppModal/deleteModal";
import DataTable from "components/dataTable/reactDataTable";
import EditAdministrativeCategory from "./EditAdministrativeCategory";

const columns = [
  {
    name: "Category Name",
    maxWidth: "350px",
    selector: (row) => (
      <div className="flex items-center gap-2 py-2">
        <img
          className="h-14 w-14 rounded"
          src={row?.logo ? row?.logo : "/assets/images/professional-man.jpeg"}
          onError={(e) => {
            e.target.src = "/assets/images/notfound.svg";
          }}
          alt="logo"
        />
        <div>
          <p className="font-semibold">{row?.name}</p>
        </div>
      </div>
    ),
    width: "300px",

  },


  {
    name: "Created Date",
    selector: (row) => (
      <span>{moment(row.createdAt).format("DD-MM-YYYY HH:mm")}</span>
    ),
  },
  {
    name: "Status",
    selector: (row) => (
      <div className="flex items-center gap-2">
        <span
          className={`${row.status === "active" ? "badge-success" : "badge-danger"
            } capitalize`}
        >
          {row.status}
        </span>
      </div>
    ),
  },
  {
    name: "Sub Categories",
    selector: (row) => (
      <div className="flex items-center gap-2">
        <Link to={`/admin/administrative-category/${row._id}`}>
          <button className="flex items-center rounded-full bg-red-800 text-white px-4 py-2 text-sm font-medium shadow-md transition duration-200">
            View ({row?.subcategoryCount ?? 0})
          </button>
        </Link>
      </div>
    ),
  },


  {
    name: "Actions",
    selector: (row) => (
      <div className="flex gap-3">
        <EditAdministrativeCategory user={row} />
        <DeleteModal
          id={row?._id}
          useDeleteMutation={useDeleteAdminCategoryMutation}
          message={"Are you sure you want to delete this category?"}
        />
      </div>
    ),
  },
];


const statusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

const AdministrativeCategories = ({ domain }) => {
  const [filters, setFilters] = useState({ domain });
  const { data, isLoading } = useFetchParentCategoriesQuery(filters); // Fetch categories based on filters

  const handleSubmit = (values) => {
    setFilters(values);
  };

  return (
    <Card extra={"mt-4 !z-5 overflow-hidden"}>
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          All {domain} Categories
        </h4>
        <Link to={`/admin/add-administrative-category`}>
          <Button>Add Category</Button>
        </Link>
      </div>

      <div className="p-5">
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ name: "", status: "" }}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <div className="grid grid-cols-4 gap-3">
                <div>
                  <Field
                    label="Search By Name"
                    name="name"
                    type="text"
                    placeholder="Enter name"
                    component={InputField}
                  />
                </div>
                <div>
                  <label
                    htmlFor="status"
                    className="mb-2 block text-sm font-bold text-gray-900 dark:text-white"
                  >
                    Status
                  </label>
                  <Field
                    name="status"
                    selectorOptions={statusOptions}
                    selectorLabelKey="label"
                    selectorValueKey="value"
                    placeholder="status"
                    component={SelectField}
                  />
                </div>

                <div className="flex items-end">
                  <button
                    type="submit"
                    className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                  >
                    Search
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {/* Displaying categories using DataTable */}
      {isLoading ? (
        <p className="my-5 py-5">Loading...</p>
      ) : (
        <DataTable
          loadingMessage="Loading..."
          isLoading={isLoading}
          columns={columns}
          data={data || []} // Ensure data is always an array
          noDataMessage={<p className="my-5 py-5">No Data found!</p>}
        />
      )}
    </Card>
  );
};

export default AdministrativeCategories;
