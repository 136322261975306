import React from 'react';
import { Input, Label } from 'reactstrap';

const DomainCard = ({ domain, index, handleImageChange, imagePreview }) => {
  const renderImagePreview = () => {
    if (imagePreview && imagePreview instanceof File) {
      return (
        <img
          src={URL.createObjectURL(imagePreview)} 
          alt="Domain Image Preview"
          className="w-full h-40 object-contain rounded-xl transition-transform duration-200 transform group-hover:scale-105"
        />
      );
    }
    
    if (imagePreview && typeof imagePreview === 'string') {
      return (
        <img
          src={imagePreview} 
          alt="Domain Image Preview"
          className="w-full h-40 object-contain rounded-xl transition-transform duration-200 transform group-hover:scale-105"
        />
      );
    }

    return null;
  };

  return (
    <div className="bg-white border border-gray-200 rounded-xl p-6  transition-all duration-300 transform hover:scale-105 flex flex-col items-center justify-between space-y-4 min-h-[350px]">
<h3 className="text-xl font-semibold text-gray-800 uppercase text-center">{domain}</h3>

      <div className="w-full">
        <Label
          for={`domainImage-${index}`}
          className="block text-sm font-medium text-gray-600 mb-2"
        >
          Upload Image
        </Label>
        <Input
          type="file"
          id={`domainImage-${index}`}
          accept="image/*"
          onChange={(e) => handleImageChange(e, index)}
          className="block  w-full px-4 py-2 text-sm border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-400 transition-colors"
        />
      </div>

      <div className="w-full mt-4">
        {renderImagePreview() || (
          <div className="h-40 bg-gray-200 rounded-xl flex items-center justify-center text-gray-400">
            No Image Preview
          </div>
        )}
      </div>
    </div>
  );
};

export default DomainCard;
