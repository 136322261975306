import React from 'react';

const CreatePage = ({ pageName, onCreatePage }) => {
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const formattedPageName = capitalizeFirstLetter(pageName);

  return (
    <div className="flex items-center justify-center p-24 px-4">
      <div className="flex flex-col items-center justify-center p-8 border-2 border-red-300 rounded-lg shadow-xl bg-white max-w-lg mx-auto">
        <div className="bg-red-100 p-4 rounded-full mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-16 h-16 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 4v16m8-8H4"
            />
          </svg>
        </div>
        <h2 className="mt-4 text-2xl font-semibold text-gray-800 text-center">
          No <span className="font-bold">{formattedPageName}</span> Page Found!
        </h2>
        <p className="mt-2 text-lg text-gray-600 text-center">
          It looks like the <span className="font-bold">{formattedPageName}</span> page doesn't exist. You can create a new one now.
        </p>
        <button
          className="mt-6 px-8 py-3 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700 transition-colors w-full sm:w-auto"
          onClick={onCreatePage}
        >
          Create <span className="font-bold">{formattedPageName}</span> Page
        </button>
      </div>
    </div>
  );
};

export default CreatePage;
