import React from "react";
import { useParams, Link } from "react-router-dom";
import DataTable from "components/dataTable/reactDataTable";
import moment from "moment";
import { Edit } from "react-feather";
import DeleteModal from "components/AppModal/deleteModal";
import Card from "components/card";
import { Button } from "components/ui-elements/Button";
import { useNavigate } from "react-router-dom";
import { useFetchSectionsByAdministrativeTypeQuery,useDeleteSectionMutation } from "../../../../redux/queries";

const AdministrativeSections = () => {
  const { typeId } = useParams(); // Get the typeId from the URL
  const { data: sections, isLoading } = useFetchSectionsByAdministrativeTypeQuery(typeId); // Fetch sections based on typeId
  const navigate = useNavigate();

  const columns = [
    {
      name: "Section Title",
      selector: (row) => (
        <div className="flex items-center gap-2 py-2">
          <img
            className="h-14 w-14 rounded"
            src={row?.image ? row.image : "/assets/images/professional-man.jpeg"}
            onError={(e) => {
              e.target.src = "/assets/images/notfound.svg";
            }}
            alt="image"
          />
          <div>
            <p className="font-semibold">{row.title}</p>
          </div>
        </div>
      ),
      width: "400px", // Set a width if needed
    },
    {
      name: "Created Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY HH:mm"),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex gap-3">
          <span
            onClick={() => navigate(`/admin/update-sections/${row._id}`)} // Navigate to update section route
            className="cursor-pointer flex items-center"
            title="Edit"
          >
            <Edit className="h-4 w-4" />
          </span>
          <DeleteModal
            id={row?._id}
            useDeleteMutation={useDeleteSectionMutation}
            message={"Are you sure you want to delete this section?"}
          />
        </div>
      ),
    },
  ];

  return (
    <Card extra={"mt-4 !z-5 overflow-hidden"}>
      <div className="flex justify-between items-center bg-white px-4 py-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Administrative Sections
        </h4>
        <Link to={`/admin/add-section/${typeId}`}>
          <Button>Add Section</Button>
        </Link>
      </div>

      <div className="p-5">
        {isLoading ? (
          <p className="my-5 py-5">Loading...</p>
        ) : (
          <DataTable
            columns={columns}
            data={sections || []}
            noDataMessage={<p>No Sections found!</p>}
          />
        )}
      </div>
    </Card>
  );
};

export default AdministrativeSections;
