import React, { useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { Col, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import { successToast, errorToast } from "utils";
import { ErrorMessage } from "components/common/error-msg";
import { useNavigate, useParams } from "react-router-dom";
import MyEditor from "components/MyEditor";
import DropZone from "components/dropzone";
import { convertToFormData } from "utils";
import { useUpdateSectionMutation,useFetchSectionByIdQuery } from "../../../../redux/queries";

const UpdateSections = () => {
    const navigate = useNavigate();
    const { sectionId } = useParams();
    const { data: section, isLoading } = useFetchSectionByIdQuery(sectionId);
    const [updateSection] = useUpdateSectionMutation();

    const initialValues = {
        title: section?.title || '',
        description: section?.description || '',
        image: section?.image ? [section.image] : [],
    };

    const handleSubmit = async (values, { resetForm }) => {
        try {
            const body = convertToFormData(values);
            const response = await updateSection({ sectionId, body }).unwrap();
            successToast(response.message);
            resetForm();
            const administrativeTypeId = section.administrativeType;
            navigate(`/admin/sections/${administrativeTypeId}`);
        } catch (error) {
            errorToast(error.message || "An error occurred");
        }
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        image: Yup.mixed().required("Image is required"),
    });

    if (isLoading) {
        return <p className="my-5 py-5">Loading...</p>;
    }

    return (
        <div className="p-4 bg-white rounded shadow-md">
            <h2 className="text-xl font-bold mb-4">Update Section</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, setFieldValue }) => (
                    <Form>
                        <Row className="mb-4">
                            <Col md={12}>
                                <FormGroup>
                                    <Field
                                        label="Section Title"
                                        placeholder="Enter Section Title"
                                        name="title"
                                        component={InputField}
                                    />
                                    <ErrorMessage error={errors.title} />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <label className="mb-2">Section Description</label>
                                    <MyEditor
                                        editorData={values.description}
                                        onChange={(data) => setFieldValue('description', data)}
                                    />
                                    <ErrorMessage error={errors.description} />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <DropZone
                                        setFieldValue={setFieldValue}
                                        name="image"
                                        label="Add an image"
                                        accept={{
                                            "image/*": [".png", ".jpg", ".jpeg", ".svg", ".webp"],
                                        }}
                                        values={values}
                                    />
                                    <ErrorMessage error={errors.image} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="linear rounded bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700"

                            >
                                Update Section
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default UpdateSections;
