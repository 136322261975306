import { categoriesTags } from "../../tags";
import { transformResponse } from "../helpers";
import { injectApiEndpoints } from "../../api";

const url = "/administrative-category"; 
const appendUrl = (segment) => `${url}/${segment}`;

const adminCategoryApis = injectApiEndpoints({
  endpoints: (builder) => ({
    createAdminCategory: builder.mutation({
      query: (body) => ({
        url: `${url}/add`, // Use "add" for create
        method: "post",
        body,
      }),
      invalidatesTags: [categoriesTags],
    }),
    
    fetchAdminCategories: builder.query({
      query: (filters = {}) => {
        let query = "";
        if (filters.domain) query += `domain=${filters.domain}&`;
        if (filters.name) query += `name=${filters.name}&`;
        return {
          url: `${url}/all?${query}`,
          method: "get",
        };
      },
      transformResponse,
      providesTags: [categoriesTags],
    }),
    
    updateAdminCategory: builder.mutation({
      query: ({ id, body }) => ({
        url: `${url}/update/${id}`, 
        method: "put",
        body,
      }),
      invalidatesTags: [categoriesTags],
    }),
    
    deleteAdminCategory: builder.mutation({
      query: (id) => ({
        url: `${url}/delete/${id}`, // Use "delete" for delete
        method: "delete",
      }),
      invalidatesTags: [categoriesTags],
    }),

    // New endpoint to fetch parent categories
    fetchParentCategories: builder.query({
      query: () => ({
        url: `${url}/parents`, // Endpoint to get parent categories
        method: "get",
      }),
      transformResponse,
      providesTags: [categoriesTags],
    }),

    // New endpoint to fetch subcategories by parent ID
    fetchSubcategoriesByParent: builder.query({
      query: (parentId) => ({
        url: `${url}/subcategories/${parentId}`, // Endpoint to get subcategories by parent ID
        method: "get",
      }),
      transformResponse,
      providesTags: [categoriesTags],
    }),
  }),
});

export const {
  useCreateAdminCategoryMutation,
  useFetchAdminCategoriesQuery,
  useUpdateAdminCategoryMutation,
  useDeleteAdminCategoryMutation,
  useFetchCategoriesOptionsByDomainQuery,
  useFetchParentCategoriesQuery,      
  useFetchSubcategoriesByParentQuery,   
} = adminCategoryApis;
