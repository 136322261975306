import { typesTags } from "../../tags"; 
import { injectApiEndpoints } from "../../api";
import { transformResponse } from "../helpers";

const url = "/sections"; 
const appendUrl = (segment) => `${url}/${segment}`; // Adjusted to point to sections directly

const adminSectionApis = injectApiEndpoints({
    endpoints: (builder) => ({
        addSection: builder.mutation({
            query: (body) => ({
                url: `${url}/add`, // Endpoint for adding a section
                method: "post",
                body,
            }),
            invalidatesTags: [typesTags],
        }),

        fetchSections: builder.query({
            query: (typeId) => ({
                url: `${appendUrl(typeId)}`, // Fetch sections for a specific typeId
                method: "get",
            }),
            transformResponse,
            providesTags: [typesTags],
        }),

        fetchSectionById: builder.query({ // New query for fetching a section by ID
            query: (sectionId) => ({
                url: `${url}/id/${sectionId}`, // Endpoint for fetching section by ID
                method: "get",
            }),
            transformResponse,
            providesTags: [typesTags],
        }),
        updateSection: builder.mutation({
            query: ({ sectionId, body }) => ({
                url: `${appendUrl(sectionId)}`,
                method: "put",
                body,
            }),
            invalidatesTags: [typesTags],
        }),

        deleteSection: builder.mutation({
            query: (sectionId) => ({
                url: `${appendUrl(sectionId)}`,
                method: "delete",
            }),
            invalidatesTags: [typesTags],
        }),
        fetchSectionsByAdministrativeType: builder.query({
            query: (administrativeTypeId) => ({
                url: `${url}/admt-type/${administrativeTypeId}`, // New endpoint
                method: "get",
            }),
            transformResponse,
            providesTags: [typesTags],
        }),
    }),
});

export const {
    useAddSectionMutation,
    useFetchSectionsQuery,
    useFetchSectionByIdQuery,
    useUpdateSectionMutation,
    useDeleteSectionMutation,
    useFetchSectionsByAdministrativeTypeQuery
} = adminSectionApis;
