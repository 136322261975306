import React from "react";
import { useDeleteEducationCourseMutation, useGetEducationCoursesQuery } from "../../../redux/queries";
import { FaEdit } from "react-icons/fa";
import DataTable from "../../dataTable/reactDataTable";
import DeleteModal from "../../AppModal/deleteModal";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const columns = (navigate) => [ // Pass navigate as a parameter
    {
        name: 'Image',
        selector: row => (row.image ? row.image : ''),
        cell: row => (
            <img
                className="h-14 w-14 rounded"
                src={row.image ? row.image : '/assets/images/notfound.svg'}
                alt="Course Image"
                onError={(e) => e.target.src = "/assets/images/notfound.svg"} // Fallback for broken images
            />
        )
    },
    {
        name: 'Course Name',
        selector: row => row.name,
        cell: row => row.name
    },
    {
        name: 'Description',
        selector: row => row.description,
        cell: row => row.description
    },
    {
        name: 'Category Name',
        selector: row => row.category ? row.category.name : 'N/A',
        cell: row => row.category ? row.category.name : 'N/A'
    },
    {
        name: 'Actions',
        cell: row => (
            <div className="flex space-x-2">
                <button 
                    className="text-blue-500 hover:text-blue-700"
                    onClick={() => navigate(`/admin/edit-education-course/${row._id}`)} 
                >
                    <FaEdit />
                </button>

                <DeleteModal id={row?._id}
                    useDeleteMutation={useDeleteEducationCourseMutation}
                    message={"Are you sure you want to delete this Course?"}
                />
            </div>
        )
    }
];

const CourseList = (filters) => {
    const { data: courses, isLoading: coursesLoading, error } = useGetEducationCoursesQuery(filters);
    const navigate = useNavigate(); // Use useNavigate here

    return (
        <div className="p-5">
            {coursesLoading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}
            {courses && (
                <DataTable
                    columns={columns(navigate)} // Pass navigate to columns
                    data={courses}
                    responsive
                />
            )}
            {courses && courses.length === 0 && <p>No courses available.</p>}
        </div>
    );
};

export default CourseList;
