import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

const CollapsibleSection = ({ children, title, defaultCollapsed = false }) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  const toggleCollapse = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  return (
    <div className="relative mb-6 bg-white border border-gray-300 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
      <div
        className="flex items-center justify-between p-6 cursor-pointer"
        onClick={toggleCollapse}
      >
        <h2 className="text-2xl font-semibold text-gray-800">{title}</h2>
        <div
          className={`transform transition-transform duration-300 ${
            isCollapsed ? 'rotate-180' : ''
          }`}
        >
          {isCollapsed ? (
            <FaAngleDown className="h-7 w-7 text-gray-600 hover:text-gray-800 transition-all duration-300" />
          ) : (
            <FaAngleUp className="h-7 w-7 text-gray-600 hover:text-gray-800 transition-all duration-300" />
          )}
        </div>
      </div>

      <div
        className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
          isCollapsed ? 'max-h-0' : 'max-h-[1000px]'
        }`}
      >
        <div className="px-6 pb-6">{children}</div>
      </div>
    </div>
  );
};

export default CollapsibleSection;
