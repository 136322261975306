import React from "react";
import { useParams, Link } from "react-router-dom";
import DataTable from "components/dataTable/reactDataTable";
import moment from "moment";
import { Trash, Edit } from "react-feather";
import DeleteModal from "components/AppModal/deleteModal";
import { useDeleteAdminTypeMutation, useFetchAdminTypesBySubcategoryQuery } from "../../../../redux/queries";
import Card from "components/card";
import { Button } from "components/ui-elements/Button";
import { useNavigate } from "react-router-dom";

const AdministrativeTypes = () => {
  const { parentId } = useParams();
  const { data: types, isLoading } = useFetchAdminTypesBySubcategoryQuery(parentId);
  const navigate = useNavigate();

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Created Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY HH:mm"),
    },

    {
      name: "View Sections",
      selector: (row) => (
        <Link to={`/admin/sections/${row._id}`}>
          <button className="flex items-center rounded-full bg-red-800 text-white px-4 py-2 text-sm font-medium shadow-md transition duration-200">
            View Sections
          </button>
        </Link>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex gap-3">
          <span
            onClick={() => navigate(`/admin/add-administrative-type/${parentId}/${row._id}`)}
            className="cursor-pointer flex items-center"
            title="Edit"
          >
            <Edit className="h-4 w-4" />
          </span>
          <DeleteModal
            id={row._id}
            useDeleteMutation={useDeleteAdminTypeMutation}
            message={"Are you sure you want to delete this type?"}
          />
        </div>
      ),
    },
  ];

  return (
    <Card extra={"mt-4 !z-5 overflow-hidden"}>
      <div className="flex justify-between items-center bg-white px-4 py-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Administrative Types
        </h4>
        <Link to={`/admin/add-administrative-type/${parentId}`}>
          <Button>Add Type</Button>
        </Link>
      </div>

      <div className="p-5">
        {isLoading ? (
          <p className="my-5 py-5">Loading...</p>
        ) : (
          <DataTable
            columns={columns}
            data={types || []}
            noDataMessage={<p>No Types found!</p>}
          />
        )}
      </div>
    </Card>
  );
};

export default AdministrativeTypes;
