import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FaUser, FaEnvelope, FaPhone, FaComment, FaRegCalendarAlt } from 'react-icons/fa'; 

const ContactMessageModal = ({ isOpen, onClose, contactData }) => {
    return (
        <Modal isOpen={isOpen} toggle={onClose} className="max-w-4xl">
            <ModalHeader toggle={onClose} className="bg-gray-100 border-b border-gray-300">
                <h5 className="font-bold text-xl">Contact Details</h5>
            </ModalHeader>
            <ModalBody className="max-h-[600px] overflow-y-auto p-4">
                <div className="mb-2 border border-red-300 rounded-lg shadow-sm p-3">
                    <div className="grid grid-cols-1 gap-2">
                        <div className="mb-2 flex flex-col">
                            <div className="flex items-center">
                                <FaUser className="mr-2 text-gray-600" />
                                <label className="font-medium">Name:</label>
                                <p className="text-gray-700 ml-2">{contactData?.firstName} {contactData?.lastName}</p>
                            </div>
                            <hr className="my-2 border-gray-300" />
                        </div>
                        <div className="mb-2 flex flex-col">
                            <div className="flex items-center">
                                <FaEnvelope className="mr-2 text-gray-600" />
                                <label className="font-medium">Email:</label>
                                <p className="text-gray-700 ml-2">{contactData?.email}</p>
                            </div>
                            <hr className="my-2 border-gray-300" />
                        </div>
                        <div className="mb-2 flex flex-col">
                            <div className="flex items-center">
                                <FaPhone className="mr-2 text-gray-600" />
                                <label className="font-medium">Contact:</label>
                                <p className="text-gray-700 ml-2">{contactData?.contact}</p>
                            </div>
                            <hr className="my-2 border-gray-300" />
                        </div>
                        <div className="mb-2 flex flex-col">
                            <div className="flex items-start">
                                <FaComment size={24} className="mr-2 text-gray-600" /> 
                                <label className="font-medium">Message:</label>
                            </div>
                            <p className="text-gray-700 ml-8">{contactData?.message}</p> 
                            <hr className="my-2 border-gray-300" />
                        </div>
                        <div className="mb-2 flex flex-col">
                            <div className="flex items-center">
                                <FaRegCalendarAlt className="mr-2 text-gray-600" />
                                <label className="font-medium">Date & Time:</label>
                                <p className="text-gray-700 ml-2">
                                    {new Date(contactData?.createdAt).toLocaleString(undefined, {
                                        year: 'numeric',
                                        month: 'long', // Full month name
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                        hour12: true, // Enable AM/PM format
                                    })}
                                </p>
                            </div>
                            <hr className="my-2 border-gray-300" />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-secondary" onClick={onClose}>
                    Close
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default ContactMessageModal;
