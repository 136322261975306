import { EducationCourseTags } from '../tags';
import { injectApiEndpoints } from '../api';
import { transformResponse } from './helpers';

const url = '/education-courses';
const appendUrl = (segment) => `${url}/${segment}`;

const educationCoursesApis = injectApiEndpoints({
  endpoints: (builder) => ({
    createEducationCourse: builder.mutation({
      query: (body) => ({
        url: `${url}/create`,
        method: 'post',
        body,
      }),
    }),
    getEducationCourses: builder.query({
      query: (filters = {}) => {
        let query = '';
        if (filters.category) query += `category=${filters.category}&`;
        if (filters.name) query += `name=${filters.name}&`;
        return {
          url: `${url}?${query}`,
          method: 'get',
        };
      },
      transformResponse: transformResponse,
      providesTags: [EducationCourseTags],
    }),

    deleteEducationCourse: builder.mutation({
      query: (id) => ({
        url: appendUrl(`delete/${id}`),
        method: 'delete',
      }),
      invalidatesTags: [EducationCourseTags]
    }),
    createDynamicForm: builder.mutation({
      query: (body) => ({
        url: '/dynamic-forms/create',
        method: 'post',
        body,
      }),
      transformResponse: transformResponse,
    }),

    updateDynamicForm: builder.mutation({
      query: ({ formId, ...body }) => ({
        url: `/dynamic-forms/${formId}/update`,
        method: 'put',
        body,
      }),
      transformResponse: transformResponse,
    }),
    getAllFormSubmissions: builder.query({
      query: () => ({
        url: `${url}/submissions`,
        method: 'get',
      }),
      transformResponse: transformResponse,
      providesTags: [EducationCourseTags],
    }),
    getEducationCourseById: builder.query({
      query: (id) => ({
        url: appendUrl(`by-id/${id}`),
        method: 'get',
      }),
      transformResponse: transformResponse,
      providesTags: [EducationCourseTags],
    }),

    updateEducationCourse: builder.mutation({
      query: ({ id, body }) => ({
        url: appendUrl(id),
        method: 'put',
        body,
      }),
      transformResponse: transformResponse,
    }),
  }),
});

export const {
  useCreateDynamicFormMutation,
  useUpdateDynamicFormMutation,
  useCreateEducationCourseMutation,
  useGetEducationCoursesQuery,
  useUpdateEducationCourseMutation,
  useDeleteEducationCourseMutation,
  useGetAllFormSubmissionsQuery,
  useGetEducationCourseByIdQuery
} = educationCoursesApis;
