import Card from "components/card";
import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { Col, Label } from "reactstrap";
import {  useGetCategoriesOptionsByDomainQuery} from "../../../redux/queries";
import SelectField from "components/fields/SelectField";
import { useNavigate } from "react-router-dom";
import CourseList from "../../../components/Education/CourseList";

const AllEducationCourses = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({ role: "professional", domain: "notary" });

  const { data: designationsOptions, isLoading: designationsLoading } =
    useGetCategoriesOptionsByDomainQuery("notary", {
      skip: !"notary",
    });


  const handleSubmit = (values) => {
    setFilters(values);
  };

  const handleAddCourseClick = () => {
    navigate('/admin/add-education-course');
  };


  return (
    <>
      <Card extra="mt-4 !z-5 overflow-hidden">
        <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:bg-navy-700 dark:shadow-none">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            All Education Courses
          </h4>
          <button
            onClick={handleAddCourseClick}
            className="rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
          >
            Add Education Course
          </button>
        </div>
        <div className="p-5">
          <Formik
            onSubmit={handleSubmit}
            initialValues={{ designation: "", role: "professional", domain: "notary", status: "" }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <div className="grid grid-cols-4 gap-3">
                  <Col xs="12" md="12">
                    <Label
                      className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                      for="designation"
                    >
                      Courses
                    </Label>
                    <Field
                      name="designation"
                      placeholder="Enter courses"
                      component={SelectField}
                      selectorOptions={designationsOptions}
                      isLoading={designationsLoading}
                      selectorLabelKey="label"
                      selectorValueKey="value"
                    />
                  </Col>
                  <div className="flex items-end">
                    <button
                      type="submit"
                      className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <CourseList filters={filters} />
      </Card>
    </>
  );
};

export default AllEducationCourses;
