import { injectApiEndpoints } from "../api";
import { transformResponse } from "./helpers";

export const ContactApi = injectApiEndpoints({
  endpoints: (build) => ({
    createContact: build.mutation({
      query: (body) => ({
        url: "/public/contacts/create",
        method: "post",
        body,
      }),
    }),
    updateContact: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/public/contacts/update-contact/${id}`,
        method: "put",
        body,
      }),
    }),
    deleteContact: build.mutation({
      query: (id) => ({
        url: `/public/contacts/delete-contact/${id}`,
        method: "delete",
      }),
    }),
    getAllContacts: build.query({
      query: ({ page, limit }) => ({
        url: `/public/contacts/all-contacts?page=${page}&limit=${limit}`,
        method: "get",
      }),
      transformResponse,
    }),

    getContactById: build.query({
      query: (id) => ({
        url: `/public/contacts/by-id/${id}`,
        method: "get",
      }),
      transformResponse,
    }),
  }),
});

export const {
  useCreateContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useGetAllContactsQuery,
  useGetContactByIdQuery,
} = ContactApi;
