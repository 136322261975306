import { injectApiEndpoints } from "redux/api";

export const HomeCmsApi = injectApiEndpoints({
  endpoints: (build) => ({
    createHeroSection: build.mutation({
      query: (body) => ({
        url: "/home-cms/create-hero-section", 
        method: "post",
        body, 
      }),
    }),

    // Update Hero Section
    updateHeroSection: build.mutation({
      query: ({ body, sectionId }) => ({
        url: `/home-cms/update-hero-section/${sectionId}`, // Pointing to the Hero Section Update API
        method: "put",
        body, 
      }),
    }),

    // Create Domain Section
    createDomainSection: build.mutation({
      query: (body) => ({
        url: "/home-cms/create-domain-section", // Pointing to the Domain Section API
        method: "post",
        body, // Structured formData for Domain Section
      }),
    }),
    updateDomainSection: build.mutation({
      query: ({ body, sectionId }) => ({
        url: `/home-cms/update-domain-section/${sectionId}`,
        method: "put",
        body,
      }),
    }),

    // Create Promotion Section
    createPromotionSection: build.mutation({
      query: (body) => ({
        url: "/home-cms/create-promotion-section", // Pointing to the Promotion Section API
        method: "post",
        body, // Structured formData for Promotion Section
      }),
    }),

    getAllSections: build.query({
      query: (pageName) => ({
        url: `/home-cms/get-all-sections/${pageName}`, 
        method: "get",
      }),
    }),
    updatePromotionSection: build.mutation({
      query: ({ body, sectionId }) => ({
        url: `/home-cms/update-promotion-section/${sectionId}`, 
        method: "put",
        body, 
      }),
    }),
  }),
});

export const {
  useCreateHeroSectionMutation,
  useUpdateHeroSectionMutation, // New hook for updating Hero Section
  useCreateDomainSectionMutation,
  useCreatePromotionSectionMutation,
  useGetAllSectionsQuery,
  useUpdateDomainSectionMutation,
  useUpdatePromotionSectionMutation
} = HomeCmsApi;
