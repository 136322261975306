import Card from "components/card";
import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import DataTable from "components/dataTable/reactDataTable";
import { useGetAllFormSubmissionsQuery } from "../../../redux/queries";
import SubmittedFormModal from "components/submittedFormModal/SubmittedFormModal";

const AllSubmittedForms = () => {
    const { data: submissions, isLoading, error } = useGetAllFormSubmissionsQuery();
    const [selectedForm, setSelectedForm] = useState(null);
    const [userFirstName, setUserFirstName] = useState('');
    const [courseName, setCourseName] = useState('');

    const viewFormData = (row) => {
        setSelectedForm(row);
    };

    const columns = [
        {
            name: 'User',
            selector: row => `${row.user.firstName} ${row.user.lastName}`,
            cell: row => `${row.user.firstName} ${row.user.lastName}` || 'Anonymous'
        },
        {
            name: 'Course Name',
            selector: row => row.educationCourse.name,
            cell: row => row.educationCourse.name || 'N/A'
        },
        {
            name: 'Course Category',
            selector: row =>row?.educationCourse?.category?.name,
            cell: row => row?.educationCourse?.category?.name || 'N/A'
        },
      
        {
            name: 'Actions',
            cell: row => (
                <div className="flex space-x-2">
                    <button
                        className="text-blue-500 hover:text-blue-700"
                        onClick={() => viewFormData(row)}
                    >
                        <FaEye />
                    </button>
                </div>
            )
        }
    ];

    const handleSearch = () => {
        // Trigger the API call with the new filters
        // You might want to update your query parameters here
    };

    return (
        <>
            <Card extra="mt-4 !z-5 overflow-hidden">
                <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:bg-navy-700 dark:shadow-none">
                    <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                        All Submitted Forms
                    </h4>
                </div>
                <div className="p-5">
                    {/* <div className="flex items-center mb-4">
                        <input
                            type="text"
                            placeholder="Search by user first name"
                            value={userFirstName}
                            onChange={(e) => setUserFirstName(e.target.value)}
                            className="border rounded-md px-2 py-1 mr-2"
                        />
                        <input
                            type="text"
                            placeholder="Search by course name"
                            value={courseName}
                            onChange={(e) => setCourseName(e.target.value)}
                            className="border rounded-md px-2 py-1 mr-2"
                        />
                        <button
                            onClick={handleSearch}
                            className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                        >
                            Search
                        </button>
                    </div> */}

                    {isLoading && <p>Loading...</p>}
                    {error && <p>Error: {error.message}</p>}
                    {submissions && (
                        <DataTable
                            columns={columns}
                            data={submissions}
                            responsive
                        />
                    )}
                    {submissions?.length === 0 && <p>No submissions available.</p>}
                </div>
            </Card>
            {selectedForm && (
                <SubmittedFormModal
                    isOpen={!!selectedForm}
                    onClose={() => setSelectedForm(null)}
                    formData={selectedForm?.formData}
                />
            )}
        </>
    );
};

export default AllSubmittedForms;
