import React, { useMemo, useState } from "react";
import DataTable from "../dataTable/reactDataTable";
import EditUserModal from "./EditUserModal";
import { FaUser } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa6";
import { useGetUsersQuery, useMakeMemberPaidMutation, useSendResetLinkMutation } from "../../redux/queries";
import DeleteUserModal from "./DeleteUserModal";
import CircularPagination from "components/pagination/CircularPagination";
import { Slash } from "react-feather";
import { MoreVertical } from "react-feather";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Edit } from "react-feather";
import { Trash } from "react-feather";
import { toast } from "react-toastify";
import ResetPasswordModal from "components/resetPassword/ResetPasswordModal";
import { FcPaid } from "react-icons/fc";
import Confirmation from "components/confirmation";

const UserList = ({ filters, setFilters }) => {
  const { data, isLoading } = useGetUsersQuery(filters);
  const [sendResetLink] = useSendResetLinkMutation();
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [makeMemberPaid] = useMakeMemberPaidMutation();
  const handleOpenResetPasswordModal = (email) => {
    setSelectedUserEmail(email);
    setResetPasswordModalOpen(true);
  };

  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [userIdToMakePaid, setUserIdToMakePaid] = useState(null);
  const handleConfirmMakeMemberPaid = async () => {
    try {
      await makeMemberPaid(userIdToMakePaid).unwrap();
      toast.success("Member has been marked as paid.");
      setConfirmationOpen(false);
    } catch (error) {
      toast.error("Failed to update member status.");
    }
  };

  { console.log('useriddddddd', userIdToMakePaid) }
  { console.log('confirrrrrrrrrrr', isConfirmationOpen) }
  const columns = useMemo(() => {
    const baseColumns = [
      {
        name: "User",
        maxWidth: "350px",
        selector: (row) => (
          <div className="flex items-center gap-2 py-2">
            <img
              className="h-10 w-10 rounded-full object-cover"
              src={row?.avatar || "/assets/images/images.png"}
              alt="Rounded avatar"
            />
            <div>
              <p className="font-semibold">{row?.firstName} {row?.lastName}</p>
              <span className="truncate lowercase text-gray-700" title={row?.email}>
                {row?.email}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Role",
        selector: (row) => (
          <div className="flex items-center gap-1">
            <span className="rounded-full bg-red-400 p-1 text-white">
              {row.role === "member" ? <FaUser /> : <FaBriefcase />}
            </span>
            <span className="font-semibold capitalize">{row.role}</span>
          </div>
        ),
      },
      {
        name: "Status",
        selector: (row) => <span className="badge-success">Active</span>,
      },

      {
        name: "Actions",
        selector: (row) => (
          <div className="d-flex gap-3 align-items-center">
            <EditUserModal user={row} />
            <UncontrolledDropdown>
              <DropdownToggle
                className="hide-arrow d-flex align-items-center justify-content-center p-2 "
                color="transparent"
                size="sm"
              >
                <MoreVertical size={15} />
              </DropdownToggle>
              <DropdownMenu container="body" className="shadow-lg rounded">
                <DropdownItem
                  className="d-flex align-items-center"
                  onClick={() => handleOpenResetPasswordModal(row.email)}
                >
                  <Edit size={15} />
                  <span className="align-middle">Reset Password</span>
                </DropdownItem>
                {row?.role === "member" && row?.eligibleForServices === false && (
                  <DropdownItem className="d-flex align-items-center" onClick={() => setUserIdToMakePaid(row.id)}>
                    <FcPaid size={15} />
                    <span className="align-middle">
                      <Confirmation
                        title="Confirm Payment"
                        message="Are you sure you want to mark this member as paid?"
                        handleConfirm={async () => {
                          try {
                            await makeMemberPaid(row.id).unwrap();
                            toast.success("Member has been marked as paid.");
                          } catch (error) {
                            toast.error("Failed to update member status.");
                          }
                        }}
                      >
                        Paid Member
                      </Confirmation>
                    </span>
                  </DropdownItem>
                )}
                <DropdownItem className="d-flex align-items-center">
                  {row.role !== "admin" ? (
                    <>
                      <DeleteUserModal user={row} id={row.id} buttonText="Delete User" />
                    </>
                  ) : (
                    <>
                      <Slash className="me-50" size={15} />
                      <span className="align-middle">View User</span>
                    </>
                  )}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
      }

    ];


    if (filters.role === "professional") {
      baseColumns.splice(1, 1, {
        name: "Domain",
        selector: (row) => (
          <div className="flex items-center gap-1">
            <span className="font-semibold capitalize">{row.domain}</span>
          </div>
        ),
      });
      baseColumns.splice(2, 0, {
        name: "designation",
        selector: (row) => (
          <div className="flex items-center gap-1">
            <span className="font-semibold capitalize">
              {row.designationToShow}
            </span>
          </div>
        ),
      });
    }
    if (filters.role === "member") {
      baseColumns.splice(2, 0, {
        name: "payment status",
        selector: (row) => (
          <div className="flex items-center gap-1">
            {row?.eligibleForServices ? (
              <span className="badge-success">Paid</span>
            ) : (
              <span className="badge-danger">UnPaid</span>
            )}
          </div>
        ),
      });
    }

    return baseColumns;
  }, [filters.role]);

  const onPageChange = (page) => {
    setFilters({ ...filters, page });
  };

  return (
    <>
      <DataTable
        loadingMessage="User fetching..."
        isLoading={isLoading}
        columns={columns}
        data={data?.users}
        noDataMessage={<p className="my-5 py-5">No Users found!</p>}
      />
      <div className="d-flex justify-content-end">
        <CircularPagination
          currentPage={data?.currentPage}
          totalPages={data?.totalPages}
          onPageChange={onPageChange}
        />
      </div>
      <ResetPasswordModal
        isOpen={isResetPasswordModalOpen}
        onClose={() => setResetPasswordModalOpen(false)}
        email={selectedUserEmail}
      />
      {isConfirmationOpen && (
        <Confirmation
          title="Confirm Payment"
          message="Are you sure you want to mark this member as paid?"
          handleConfirm={handleConfirmMakeMemberPaid}
          onCancel={() => setConfirmationOpen(false)}
        >
          <span className="flex items-center gap-3">Confirm</span>
        </Confirmation>
      )}
    </>
  );
};

export default UserList;
