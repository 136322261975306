import React from "react";
import { useParams, Link } from "react-router-dom";
import DataTable from "components/dataTable/reactDataTable";
import moment from "moment";
import DeleteModal from "components/AppModal/deleteModal";
import { useDeleteAdminCategoryMutation, useFetchSubcategoriesByParentQuery } from "../../../../redux/queries";
import Card from "components/card";
import { Button } from "components/ui-elements/Button";
import EditAdministrativeCategory from "./EditAdministrativeCategory";

const AdministrativeSubcategories = () => {
    const { parentId } = useParams();
    const { data: subcategories, isLoading } = useFetchSubcategoriesByParentQuery(parentId);

    const parentName = subcategories && subcategories.length > 0 ? subcategories[0].parent.name : '';

    const columns = [
        {
            name: "SubCategory Name",
            selector: (row) => (
                <div className="flex items-center gap-2 py-2">
                    <img
                        className="h-14 w-14 rounded"
                        src={row?.logo ? row.logo : "/assets/images/professional-man.jpeg"}
                        onError={(e) => { e.target.src = "/assets/images/notfound.svg"; }}
                        alt="logo"
                    />
                    <div>
                        <p className="font-semibold">{row?.name}</p>
                    </div>
                </div>
            ),
            width: "300px",
        },
        {
            name: "Created Date",
            selector: (row) => moment(row.createdAt).format("DD-MM-YYYY HH:mm"),
        },
        {
            name: "Status",
            selector: (row) => (
                <span className={`${row.status === "active" ? "badge-success" : "badge-danger"} capitalize`}>
                    {row.status}
                </span>
            ),
        },
        {
            name: "View Types",
            selector: (row) => (
                <Link to={`/admin/administrative-types/${row._id}`}>
                    <button className="flex items-center rounded-full bg-red-800 text-white px-4 py-2 text-sm font-medium shadow-md transition duration-200">
                        View Types
                    </button>
                </Link>
            ),
        },
        {
            name: "Actions",
            selector: (row) => (
                <div className="flex gap-3">
                    <EditAdministrativeCategory user={row} />
                    <DeleteModal
                        id={row._id}
                        useDeleteMutation={useDeleteAdminCategoryMutation}
                        message={"Are you sure you want to delete this subcategory?"}
                    />
                </div>
            ),
        },
    ];

    return (
        <Card extra={"mt-4 !z-5 overflow-hidden"}>
            <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
                {parentName && (
                    <h4 className="flex items-center text-xl font-bold text-navy-700 dark:text-white">
                        <span className="mr-2 text-3xl">
                            <i className="fas fa-folder-open"></i>
                        </span>
                        <strong className="text-2xl">Category: {parentName}</strong>
                    </h4>
                )}

                <div className="ml-auto">
                    <Link to={`/admin/add-administrative-category/${parentId}`}>
                        <Button>Add Subcategory</Button>
                    </Link>
                </div>
            </div>

            {/* Displaying subcategories using DataTable */}
            <div className="p-5">
                {isLoading ? (
                    <p className="my-5 py-5">Loading...</p>
                ) : (
                    <DataTable
                        columns={columns}
                        data={subcategories || []}
                        noDataMessage={<p>No Subcategories found!</p>}
                    />
                )}
            </div>
        </Card>
    );
};

export default AdministrativeSubcategories;
