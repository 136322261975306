import React from "react";
import DomainCategories from "components/Category/DomainCategories";

const CultureCategories = () => {
  return (
    <>
      <DomainCategories domain="culture" />
    </>
  );
};

export default CultureCategories;
