import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Input, Button, FormGroup, FormFeedback } from 'reactstrap';
import { useCreateHeroSectionMutation, useUpdateHeroSectionMutation } from 'redux/queries/cms/homeCms';
import { convertToFormData } from 'utils';
import { successToast, errorToast } from 'utils';

const HeroSection = ({ setFieldValue, values, pageId, heroData }) => {
  const [createHeroSection] = useCreateHeroSectionMutation();
  const [updateHeroSection] = useUpdateHeroSectionMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (heroData) {
      setFieldValue('heroTitle', heroData?.sectionDetails?.heroTitle);
      setFieldValue('heroImage', heroData?.sectionDetails?.heroImage);
    }
  }, [heroData, setFieldValue]);

  const validateFields = () => {
    const newErrors = {};
    if (!values.heroTitle) newErrors.heroTitle = 'Hero Title is required';
    if (!values.heroImage) newErrors.heroImage = 'Hero Image is required';
    return newErrors;
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;

    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSubmitting(true);
    setErrors({});

    const formattedData = {
      heroTitle: values.heroTitle,
      heroImage: values.heroImage,
      pageId,
      sectionName: 'hero',
      pageName: 'home',
    };

    const formData = convertToFormData(formattedData);

    try {
      if (heroData && heroData?._id) {
        const response = await updateHeroSection({
          body: formData,
          sectionId: heroData?._id,
        }).unwrap();
        successToast(response?.message);
      } else {
        const response = await createHeroSection(formData).unwrap();
        successToast(response?.message);
      }
    } catch (error) {
      console.error('Error submitting Hero Section:', error);
      errorToast('Error submitting Hero Section!');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (field, value) => {
    setFieldValue(field, value);
    if (errors[field]) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFieldValue('heroImage', file);
      if (errors.heroImage) {
        setErrors((prevErrors) => ({ ...prevErrors, heroImage: '' }));
      }
    }
  };

  return (
    <div className="section mb-2">
      <Row className="mb-4">
        <Col md={5}>
          <Label for="heroTitle">Title</Label>
          <Input
            type="text"
            id="heroTitle"
            placeholder="Enter Hero Title"
            value={values.heroTitle || ''}
            onChange={(e) => handleInputChange('heroTitle', e.target.value)}
            className="form-control"
            invalid={!!errors.heroTitle}
          />
          {errors.heroTitle && <FormFeedback>{errors.heroTitle}</FormFeedback>}
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label for="heroImage" className="d-block mb-2">Add Hero Image</Label>
            <Input
              type="file"
              id="heroImage"
              name="heroImage"
              accept="image/*"
              onChange={handleImageChange}
              className="file-input"
              invalid={!!errors.heroImage}
            />
            {errors.heroImage && <FormFeedback>{errors.heroImage}</FormFeedback>}
          </FormGroup>
        </Col>
        <Col md={2} className="d-flex align-items-center justify-content-center">
          {values.heroImage && typeof values.heroImage === 'string' ? (
            <div style={{ width: '100px', height: '100px' }}>
              <img
                src={values.heroImage}
                alt="Hero Image Preview"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '8px',
                  border: '1px solid #ddd',
                }}
              />
            </div>
          ) : (
            values.heroImage && values.heroImage.name && (
              <div style={{ width: '100px', height: '100px' }}>
                <img
                  src={URL.createObjectURL(values.heroImage)}
                  alt="Hero Image Preview"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '8px',
                    border: '1px solid #ddd',
                  }}
                />
              </div>
            )
          )}
        </Col>
      </Row>
      <div className="flex justify-end ">
        <Button
          onClick={handleSubmit}
          className=" btn-primary w-1/2 sm:w-1/3 md:w-1/4  text-white font-bold"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : heroData && heroData?._id ? 'Update Hero Section' : 'Submit Hero Section'}
        </Button>
      </div>
    </div>
  );
};

export default HeroSection;
