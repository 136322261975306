import React from "react";
import DomainCategories from "components/Category/DomainCategories";

const EducationCategories = () => {
  return (
    <>
      <DomainCategories domain="education" />
    </>
  );
};

export default EducationCategories;
