import React, { useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { Col, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import { successToast, errorToast } from "utils";
import { ErrorMessage } from "components/common/error-msg";
import { useNavigate, useParams } from "react-router-dom";
import MyEditor from "components/MyEditor";
import { useCreateAdminTypeMutation, useUpdateAdminTypeMutation, useFetchAdminTypeByIdQuery } from "../../../../redux/queries";

const AddAdministrativeTypeForm = () => {
  const navigate = useNavigate();
  const { parentId, id } = useParams();
  const [createAdminType] = useCreateAdminTypeMutation();
  const [updateAdminType] = useUpdateAdminTypeMutation();

  const { data: adminTypeData, isLoading } = useFetchAdminTypeByIdQuery(id, {
    skip: !id, // Skip if no id
  });

  // Initial values for the form
  const initialValues = {
    title: adminTypeData?.title || "",
    description: adminTypeData?.description || "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (id) {
        // Update existing type
        await updateAdminType({ id, ...values }).unwrap();
        successToast("Administrative Type updated successfully!");
      } else {
        // Create new type
        await createAdminType({ ...values, subcategoryId: parentId }).unwrap();
        successToast("Administrative Type added successfully!");
      }
      resetForm();
      navigate(`/admin/administrative-types/${parentId}`); // Redirect to the new page with parentId
    } catch (error) {
      errorToast(error.message);
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });

  useEffect(() => {
    if (adminTypeData) {
      initialValues.title = adminTypeData.title;
      initialValues.description = adminTypeData.description;
    }
  }, [adminTypeData]);

  if (isLoading) return <p>Loading...</p>;

  return (
    <div className="p-4 bg-white rounded shadow-md">
      <h2 className="text-xl font-bold mb-4">{id ? "Edit" : "Add"} Administrative Type</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Row>
              <Col md={12} className="mb-4">
                <FormGroup>
                  <Field
                    label="Title"
                    placeholder="Enter Title"
                    name="title"
                    component={InputField}
                  />
                  <ErrorMessage error={errors.title} />
                </FormGroup>
              </Col>

              <Col md={12} className="mb-4">
                <FormGroup>
                  <label>Description</label>
                  <textarea
                    name="description"
                    value={values.description}
                    onChange={(e) => setFieldValue("description", e.target.value)}
                    rows={6} // Adjust the number of rows for height
                    className="form-control" // Add your preferred styles here
                  />
                  <ErrorMessage error={errors.description} />
                </FormGroup>
              </Col>

            </Row>

            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="linear rounded bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700"
              >
                {id ? "Update Type" : "Add Type"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddAdministrativeTypeForm;
