import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import HeroSection from './HeroSection';
import DomainsSection from './DomainsSection';
import PromotionalSection from './PromotionalSection';
import { useGetPageQuery } from 'redux/queries';
import CreatePage from 'components/cms/create-page';
import CollapsibleSection from 'components/common/collapsible-sections';
import { useGetAllSectionsQuery } from 'redux/queries/cms/homeCms';
import { useCreatePageMutation } from 'redux/queries';
import { successToast } from 'utils';
import { errorToast } from 'utils';

const HomeCMS = ({ pageName = 'home' }) => {
  const initialValues = {
    heroTitle: '',
    heroImage: null,
    primaryHeading: '',
    domains: [{ domainName: '', image: null }],
    promotions: [{ title: '', description: '', image: null }],
    secondaryHeading: ''
  };

  const [formData, setFormData] = useState(initialValues);
  const { data: pageData, error, isLoading } = useGetPageQuery(pageName);

  const { data: homeData, isLoading: sectionsLoading } = useGetAllSectionsQuery('home');
  useEffect(() => {
    if (homeData) {
      console.log("datadatadatadata datadata sections:", homeData);
    }
  }, [homeData]);
  const heroSectionData = homeData?.data?.find((section) => section.sectionName === 'hero');
  const domainSectionData = homeData?.data?.find((section) => section.sectionName === 'domains');
  const promotionSectionData = homeData?.data?.find((section) => section.sectionName === 'promotions');

  const [createPage] = useCreatePageMutation();
  const handleCreatePage = async () => {
    if (!pageData) {
      try {
        const response = await createPage({ pageName }).unwrap();
        if (response.success === true) {
          successToast('Page created successfully!');
        }
        else {
          errorToast('Something went wrong.');

        }
      } catch (error) {
        console.error("Error creating page:", error);
      }
    }
  };

  if (isLoading || sectionsLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <span className="text-xl text-gray-600">Loading...</span>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      {!pageData ? (
        <CreatePage pageName={pageName} onCreatePage={handleCreatePage} />
      ) : (
        <Formik initialValues={formData} enableReinitialize>
          {({ values, setFieldValue }) => (
            <Form>
              <CollapsibleSection title="Hero Section" defaultCollapsed={false}>
                <HeroSection
                  setFieldValue={setFieldValue}
                  values={values}
                  pageId={pageData?._id}
                  heroData={heroSectionData}
                />
              </CollapsibleSection>

              <CollapsibleSection title="Domains Section" defaultCollapsed={false}>
                <DomainsSection
                  setFieldValue={setFieldValue}
                  values={values}
                  pageId={pageData?._id}
                  domainData={domainSectionData}
                />
              </CollapsibleSection>

              <CollapsibleSection title="Promotional Section" defaultCollapsed={false}>
                <PromotionalSection
                  setFieldValue={setFieldValue}
                  values={values}
                  pageId={pageData?._id}
                  promotionData={promotionSectionData}
                />
              </CollapsibleSection>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default HomeCMS;
