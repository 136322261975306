import React from "react";
import { Field, Form, Formik } from "formik";
import { Button, FormGroup } from "reactstrap";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import { successToast, errorToast } from "utils";
import { ErrorMessage } from "components/common/error-msg";
import { useNavigate, useParams } from "react-router-dom";
import { useAddSectionMutation } from "../../../../redux/queries";
import MyEditor from "components/MyEditor";
import DropZone from "components/dropzone";

const AddSections = () => {
  const navigate = useNavigate();
  const { typeId } = useParams();
  const [addSection] = useAddSectionMutation();

  const initialValues = {
    title: "",
    description: "",
    image: null,
    administrativeType: typeId,
  };

  // Define the convertToFormData function inside the component
  const convertToFormData = (data) => {
    const formData = new FormData();
    for (const key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((item) => formData.append(key, item));
      } else if (data[key] instanceof File) {
        formData.append(key, data[key]);
      } else {
        formData.append(key, data[key]);
      }
    }
    return formData;
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = convertToFormData(values);
      console.log("Converted FormData:", Array.from(formData));
      const response = await addSection(formData).unwrap();

      successToast("Section added successfully!");
      resetForm();
      navigate(`/admin/sections/${typeId}`);
    } catch (error) {
      errorToast(error.message);
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    image: Yup.mixed().required("Image is required"),
  });

  return (
    <div className="p-4 bg-white rounded shadow-md">
      <h2 className="text-xl font-bold mb-4">Add Section</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <FormGroup>
              <Field
                label="Section Title"
                placeholder="Enter Section Title"
                name="title"
                component={InputField}
              />
              <ErrorMessage error={errors.title} />
            </FormGroup>
            <FormGroup>
              <label className="mb-2">Section Description</label>
              <MyEditor
                editorData={values.description}
                onChange={(data) => setFieldValue("description", data)}
              />
              <ErrorMessage error={errors.description} />
            </FormGroup>
            <FormGroup>
              <DropZone
                setFieldValue={setFieldValue}
                errors={errors.image}
                values={values}
                label="Add an image"
                name="image"
                multiple={false}
                accept={{
                  "image/*": [".png", ".jpg", ".jpeg", ".svg", ".webp"],
                }}
              />
            </FormGroup>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="linear rounded bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700"

              >
                Add Section
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddSections;
